import axios from 'axios'
import { history, qs } from '../../routing'
import { CONNECTION_TIMEOUT } from '../../../config'
import { getToken } from '../../redux/features/token.redux'
import { store } from '../../redux'
import { AppRoutes } from '../../../routes'

const {
  REACT_APP_API_GATEWAY,
  REACT_APP_PRODUCT,
  REACT_APP_COUNTRYCODE,
  REACT_APP_BACKEND_BRAND,
  REACT_APP_WEBSITE_BASE_BRAND,
  REACT_APP_ENTRYPOINT,
} = process.env

export const TransactionStatus = {
  INITIALIZED: 'INITIALIZED',
  LEAD_SUBMITTED: 'LEAD_SUBMITTED',
  DAC_APPLICATION_STARTED: 'DAC_APPLICATION_STARTED',
  APPLICATION_STARTED: 'APPLICATION_STARTED',
  APPLICATIONFORM_STARTED: 'APPLICATIONFORM_STARTED',
  APPLICATIONFORM_COMPLETED: 'APPLICATIONFORM_COMPLETED',
  CREDIT_PENDING: 'CREDIT_PENDING',
  CREDIT_PENDING_CUSTOM_EMAIL: 'CREDIT_PENDING_CUSTOM_EMAIL',
  CREDIT_APPROVED: 'CREDIT_APPROVED',
  CREDIT_REFUSED: 'CREDIT_REFUSED',
  UPLOADED: 'DOCUMENTS_UPLOADED',
}

export function canMoveTo(toState, currentState) {
  if (currentState === TransactionStatus.INITIALIZED) {
    return (
      [
        TransactionStatus.INITIALIZED,
        TransactionStatus.DAC_APPLICATION_STARTED,
        TransactionStatus.APPLICATION_STARTED,
        TransactionStatus.APPLICATIONFORM_STARTED,
        TransactionStatus.APPLICATIONFORM_COMPLETED,
        TransactionStatus.CREDIT_PENDING,
        TransactionStatus.CREDIT_PENDING_CUSTOM_EMAIL,
        TransactionStatus.UPLOADED,
      ].indexOf(toState) !== -1
    )
  }
  if (
    currentState === TransactionStatus.APPLICATION_STARTED ||
    currentState === TransactionStatus.APPLICATIONFORM_STARTED ||
    currentState === TransactionStatus.DAC_APPLICATION_STARTED ||
    currentState === TransactionStatus.LEAD_SUBMITTED
  ) {
    return (
      [
        TransactionStatus.DAC_APPLICATION_STARTED,
        TransactionStatus.APPLICATION_STARTED,
        TransactionStatus.APPLICATIONFORM_STARTED,
        TransactionStatus.APPLICATIONFORM_COMPLETED,
        TransactionStatus.CREDIT_PENDING,
        TransactionStatus.CREDIT_PENDING_CUSTOM_EMAIL,
        TransactionStatus.UPLOADED,
      ].indexOf(toState) !== -1
    )
  }
  if (currentState === TransactionStatus.APPLICATIONFORM_STARTED) {
    return (
      [
        TransactionStatus.APPLICATIONFORM_STARTED,
        TransactionStatus.APPLICATIONFORM_COMPLETED,
        TransactionStatus.CREDIT_PENDING,
        TransactionStatus.CREDIT_PENDING_CUSTOM_EMAIL,
        TransactionStatus.UPLOADED,
      ].indexOf(toState) !== -1
    )
  }
  if (currentState === TransactionStatus.APPLICATIONFORM_COMPLETED) {
    return (
      [
        TransactionStatus.APPLICATIONFORM_COMPLETED,
        TransactionStatus.CREDIT_PENDING,
        TransactionStatus.CREDIT_PENDING_CUSTOM_EMAIL,
        TransactionStatus.UPLOADED,
      ].indexOf(toState) !== -1
    )
  }
  return false
}

let lastStatus = TransactionStatus.INITIALIZED

export function updateStatus(data) {
  const { iban, ...journeyData } = data

  if (iban || journeyData.customerData?.paymentData?.iban) {
    journeyData.customerData = {
      ...journeyData.customerData,
      paymentData: {
        iban: iban || journeyData.customerData?.paymentData?.iban,
      },
    }
  }
  if (data.customerData && !data.customerData.secondaryLastName) {
    journeyData.customerData.secondaryLastName = ''
  }

  const {
    storefront: {
      data: { entryPoint, id, product },
    },
  } = store.getState()

  const url =
    REACT_APP_WEBSITE_BASE_BRAND === 'vwfsd2o'
      ? `${REACT_APP_API_GATEWAY}/journey/${REACT_APP_PRODUCT}/${REACT_APP_COUNTRYCODE}/${REACT_APP_WEBSITE_BASE_BRAND}/${entryPoint ??
          REACT_APP_ENTRYPOINT}/status`
      : `${REACT_APP_API_GATEWAY}/journey/${REACT_APP_PRODUCT}/${REACT_APP_COUNTRYCODE}/${REACT_APP_BACKEND_BRAND}/${entryPoint ??
          REACT_APP_ENTRYPOINT}/status`
  const token = qs(document.location.search, 'token') || getToken(store)
  const cid = qs(document.location.search, 'cid')
  if (!token) return Promise.resolve()
  const Authorization = `${cid}:${token}`
  // eslint-disable-next-line no-param-reassign
  delete journeyData.customerDocuments
  if (canMoveTo(journeyData.transaction.status, lastStatus)) {
    lastStatus = journeyData.transaction.status
  }

  // eslint-disable-next-line no-param-reassign
  journeyData.transaction.status = lastStatus

  return axios
    .post(
      url,
      {
        ...journeyData,
        id,
        product,
      },
      {
        headers: {
          'Cache-Control': 'no-cache, no-store',
          Pragma: 'no-cache',
          Authorization,
        },
        timeout: CONNECTION_TIMEOUT,
        validateStatus: statusCode => statusCode === 200,
      }
    )
    .then(res => {
      // eslint-disable-next-line no-empty
      if (res.status === 200) {
      } else {
        history.push(AppRoutes.error)
      }
    })
    .catch(error => {
      history.push(AppRoutes.error)
      return error
    })
}
