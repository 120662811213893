import { PRODUCT, ENTRYPOINT } from './config'
import postalCodes from './utils/postalCodes/result.json'

export const isSubscriptionJourney = () => {
  return PRODUCT === 'subscription'
}

export const isOneShop = () => {
  return ENTRYPOINT === 'oneshop'
}

export const isZipCodeValid = zipcode =>
  `${zipcode}`.length === 5 &&
  postalCodes.some(zpc => `${zipcode}` === `${zpc.codigo_postal}`)
