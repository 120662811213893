export const SET_TOKEN = 'token//SET_TOKEN'

export function setToken(data) {
  return {
    type: SET_TOKEN,
    payload: data,
  }
}

export function getToken(store) {
  return store.token?.data || sessionStorage.getItem('token')
}

const initialState = {
  data: '',
}

export default function(state = initialState, action) {
  switch (action.type) {
    case SET_TOKEN:
      return {
        ...state,
        data: action.payload,
      }

    default:
      return state
  }
}
