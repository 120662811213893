import { createBrowserHistory } from 'history'

const history = createBrowserHistory({
  basename: process.env.REACT_APP_BASE_PATH,
})

function qs(queryString, parameter) {
  const strToMap = queryString
    .slice(1)
    .split('&')
    .reduce((acc, item) => {
      const [name, ...value] = item.split('=')
      return {
        ...acc,
        [name]: value.join('='),
      }
    }, {})
  return strToMap[parameter] === undefined ? '' : strToMap[parameter]
}

function getAdditionalQueryStrings(location) {
  const params = new URL(location).searchParams
  let additionalQueryStrings = ''
  params.forEach((value, key) => {
    if (key !== 'cid' && key !== 'token' && key !== 'brand') {
      additionalQueryStrings += `&${key}=${value}`
    }
  })
  // eslint-disable-next-line no-console
  console.debug('AdditionalQueryStrings', additionalQueryStrings)
  return additionalQueryStrings
}

export { history, qs, getAdditionalQueryStrings }
