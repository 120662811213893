/**
 * This service is a bridge between the application and whatever analytics
 * software is used.
 *
 * By defining the REACT_APP_ANALYTICS environment variable we are able to
 * either export the real or mocked implementation, so that from the application
 * point of view nothing changes and it can continue calling the analytics logic
 * as per usuall.
 */
import { cloneDeep, set, merge, get } from 'lodash'

const mock = {
  default: {},
  datalayer: {},
  pageName: null,
  pageBottom: function pageBottom() {},

  setDefaults: function setDefaults(obj) {
    Object.assign(this.default, obj)
  },
  addToDatalayer(additions) {
    Object.keys(additions).forEach(key => {
      set(this.datalayer, key, additions[key])
    })
  },

  setTrackingData(additions) {
    const clonedDefaultDatalayer = cloneDeep(this.default)
    const trackingData = {}
    window.du_tracking_pagename = additions[this.keys.pageName()]
    Object.keys(additions).forEach(key => {
      set(trackingData, key, additions[key])
    })
    window.du_digitalData = merge(
      {},
      clonedDefaultDatalayer,
      this.datalayer,
      trackingData
    )
  },
  trackViewChange: function viewChange() {
    this.track('viewChange')
  },

  trackPage: function page() {
    this.track('page')
  },

  trackInteraction: function interaction() {
    this.track('interaction')
  },

  updateScreenSize: function updateScreenSize() {
    if (window.innerWidth > 1920) {
      this.addToDatalayer({
        'design.browserResolutionBreakpoint': 'xxl',
      })
    } else if (window.innerWidth >= 1600) {
      this.addToDatalayer({
        'design.browserResolutionBreakpoint': 'xl',
      })
    } else if (window.innerWidth >= 1280) {
      this.addToDatalayer({
        'design.browserResolutionBreakpoint': 'l',
      })
    } else if (window.innerWidth >= 960) {
      this.addToDatalayer({
        'design.browserResolutionBreakpoint': 'm',
      })
    } else if (window.innerWidth >= 720) {
      this.addToDatalayer({
        'design.browserResolutionBreakpoint': 's',
      })
    } else if (window.innerWidth >= 480) {
      this.addToDatalayer({
        'design.browserResolutionBreakpoint': 'xs',
      })
    }
  },

  track: function track(action) {
    if (typeof action !== 'string') {
      // eslint-disable-next-line no-console
      console.warn('Legacy tracking code detected')
      return
    }
    // eslint-disable-next-line no-underscore-dangle
    if (!implementation.isLive || window.__CJ_DEBUG_ANALYTICS) {
      // eslint-disable-next-line no-console
      console.log(
        `track ${action}\n pageName: ${get(
          window.du_digitalData,
          this.keys.pageName()
        )}\n viewChange: ${get(
          window.du_digitalData,
          this.keys.viewChange()
        )}\n event0.type: ${get(
          window.du_digitalData,
          this.keys.eventType(0)
        )}\n event0.action: ${get(
          window.du_digitalData,
          this.keys.eventAction(0)
        )}\n event0.linkinformation: ${get(
          window.du_digitalData,
          this.keys.linkInfo(0)
        )}\n\n Datalayer:`,
        window.du_digitalData
      )
    }
    // eslint-disable-next-line
    window._satellite && window._satellite.track && window._satellite.track(action)
  },
  keys: {
    pageName: () => 'core.pageInfo.pageName',
    condition: () => 'product[0].vehicleModel[0].condition',
    viewChange: () => 'core.attributes.viewChange',
    formType: () => 'form.type',
    formName: () => 'form.name',
    linkInfo: eventCount => `event[${eventCount}].eventInfo.linkInformation`,
    eventType: eventCount => `event[${eventCount}].eventInfo.eventType`,
    eventAction: eventCount => `event[${eventCount}].eventInfo.eventAction`,
    errorCode: () => 'error.errorCode',
    errorMessage: () => 'error.errorMessage',
    errorCausingURL: () => 'error.errorCausingURL',
  },
}

const real = {
  ...mock,
  isLive: true,
  pageBottom: function pageBottom() {
    window._satellite && window._satellite.pageBottom() // eslint-disable-line
  },
}

let implementation = mock // eslint-disable-line

if (process.env.REACT_APP_ANALYTICS) {
  implementation = real
}

export { implementation as Analytics }
