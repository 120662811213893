export const RESET_FORM_DATA = 'form//RESET_FORM_DATA'

export const SAVE_FORM_DATA = 'form//SAVE_FORM_DATA'

export const ADD_CONTACT_DATA = 'form/ADD_CONTACT_DATA'

export const SAVE_FORM_PERSONAL_DATA = 'form//SAVE_FORM_PERSONAL_DATA'

export const SAVE_FORM_CONTACT_DATA = 'form//SAVE_FORM_CONTACT_DATA'

export const SAVE_FORM_EMPLOYMENT_DATA = 'form//SAVE_FORM_EMPLOYMENT_DATA'

export const SAVE_FORM_FINANCIAL_DATA = 'form//SAVE_FORM_FINANCIAL_DATA'

export const SAVE_FORM_PROTECTION_DATA = 'form//SAVE_FORM_PROTECTION_DATA'

export function saveFormData(data) {
  return {
    type: SAVE_FORM_DATA,
    payload: data,
  }
}

export function savePersonalDetailsData(data) {
  return {
    type: SAVE_FORM_PERSONAL_DATA,
    payload: data,
  }
}

export function saveContactDetailsData(data) {
  return {
    type: SAVE_FORM_CONTACT_DATA,
    payload: data,
  }
}

export function saveEmploymentData(data) {
  return {
    type: SAVE_FORM_EMPLOYMENT_DATA,
    payload: data,
  }
}

export function saveFinancialData(data) {
  return {
    type: SAVE_FORM_FINANCIAL_DATA,
    payload: data,
  }
}

export function saveDataProtectionData(data) {
  return {
    type: SAVE_FORM_PROTECTION_DATA,
    payload: data,
  }
}

export function addContactData(data) {
  return {
    type: ADD_CONTACT_DATA,
    payload: data,
  }
}

export function resetFormData() {
  return {
    type: RESET_FORM_DATA,
  }
}

export function getFormData(store) {
  return store.form.data
}

const initialState = {
  data: {},
}

export default function(state = initialState, action) {
  switch (action.type) {
    case RESET_FORM_DATA:
      return {
        ...state,
        data: {},
      }

    case SAVE_FORM_DATA:
      return {
        ...state,
        data: {
          ...state.data,
          ...action.payload,
        },
      }
    case SAVE_FORM_PERSONAL_DATA:
      return {
        ...state,
        data: {
          ...state.data,
          customerData: {
            ...state.data.customerData,
            gender: action.payload.gender,
            firstName: action.payload.firstName,
            lastName: action.payload.lastName,
            secondaryLastName: action.payload.secondaryLastName || undefined,
            dateOfBirth: action.payload.dateOfBirth,
            nationality: action.payload.nationality,
            maritalStatus: action.payload.maritalStatus,
            personalId: {
              id: action.payload.personalId,
            },
          },
        },
      }
    case SAVE_FORM_CONTACT_DATA:
      return {
        ...state,
        data: {
          ...state.data,
          customerData: {
            ...state.data.customerData,
            ...action.payload,
          },
        },
      }
    case SAVE_FORM_FINANCIAL_DATA:
      return {
        ...state,
        data: {
          ...state.data,
          customerData: {
            ...state.data.customerData,
            iban: action.payload?.iban,
            financialDisclosure: {
              ...action.payload.financialDisclosure,
            },
          },
        },
      }
    case SAVE_FORM_EMPLOYMENT_DATA:
      return {
        ...state,
        data: {
          ...state.data,
          customerData: {
            ...state.data.customerData,
            ...action.payload,
          },
        },
      }
    case SAVE_FORM_PROTECTION_DATA:
      return {
        ...state,
        data: {
          ...state.data,
          dataProtection: {
            promotion: {
              transferFinancialServicesSpain:
                action.payload.transferFinancialServicesSpain === 'true',
              transferVWServicesGroup:
                action.payload.transferVWServicesGroup === 'true',
              transferVWServicesDealer:
                action.payload.transferVWServicesDealer === 'true',
            },
            acceptPrivacyPolicy: action.payload.acceptPrivacyPolicy,
          },
        },
      }

    case ADD_CONTACT_DATA:
      return {
        ...state,
        data: {
          ...state.data,
          DACcustomerData: {
            ...state.data.DACcustomerData,
            ...action.payload,
          },
        },
      }

    default:
      return state
  }
}
