/**
 * The base resource needs to be declared in this file and not imported,
 * because it must resolve to a string directly, otheriwse it will not work in
 * the getModels method.
 */
import { isSubscriptionJourney, isOneShop } from '../../helpers'

const BASE_RESOURCE = 'base'

export const getBrandName = () => {
  if (
    typeof window.reactConfig === 'object' &&
    window.reactConfig.brand !== '%brand%'
  ) {
    return window.reactConfig.brand
  }
  return process.env.REACT_APP_WEBSITE_BRAND
}

export const getBaseBrandName = () => {
  if (
    typeof window.reactConfig === 'object' &&
    window.reactConfig.brand !== '%brand%'
  ) {
    return window.reactConfig.brand
  }
  return process.env.REACT_APP_WEBSITE_BASE_BRAND
}

export const getBronsonBrand = brand => {
  switch (brand) {
    case 'cupra':
      return 'cupra'
    case 'vwfs':
    case 'vwfsd2o':
      return 'bluelabel'
    case 'vw':
    case 'vw6':
      return 'vw6'
    case 'vwcv':
      return 'vw6'
    case 'vwn':
      return 'vw6'
    default:
      return brand
  }
}

export const getPages = brand => {
  let brandWithProduct = brand
  if (isSubscriptionJourney()) {
    brandWithProduct = `${brand}-subscription`
  } else if (isOneShop()) {
    brandWithProduct = `${brand}-oneshop`
  }

  return import(`../../resources/${brandWithProduct}/pages/index.json`).catch(
    () => {
      return import(`../../resources/${BASE_RESOURCE}/pages/index.json`)
    }
  )
}

export const getModels = brand => {
  return import(`../../resources/${brand}/models`).catch(() => {
    return import(`../../resources/${BASE_RESOURCE}/models`)
  })
}

export const getLogoSrc = async brandName => {
  let logoDefault
  let logoSmall
  const logoBrand = getBronsonBrand(brandName)

  if (brandName === 'vwn') {
    logoDefault = await import(
      `@vwfs-bronson/bronson-${logoBrand}/dist/img/logo-commercial-vehicle-es.svg`
    )

    return {
      logoDefault: logoDefault.default,
      logoSmall: logoDefault.default,
    }
  }

  switch (logoBrand) {
    case 'skoda':
      logoDefault = await import(
        `@vwfs-bronson/bronson-${logoBrand}/dist/img/logo.svg`
      )
      logoSmall = await import(
        `@vwfs-bronson/bronson-${logoBrand}/dist/img/logo-custom-small-screen.svg`
      )

      return {
        logoDefault: logoDefault.default,
        logoSmall: logoSmall.default,
      }
    case 'cupra':
    case 'vw6':
    case 'bluelabel':
    case 'seat':
    case 'audi':
      logoDefault = await import(
        `@vwfs-bronson/bronson-${logoBrand}/dist/img/logo.svg`
      )

      return {
        logoDefault: logoDefault.default,
        logoSmall: logoDefault.default,
      }
    case 'vwcv':
    default:
      logoDefault = await import(
        `@vwfs-bronson/bronson-bluelabel/dist/img/logo.svg`
      )

      logoSmall = await import(
        `@vwfs-bronson/bronson-bluelabel/dist/img/logo-small-screen.svg`
      )

      return {
        logoDefault: logoDefault.default,
        logoSmall: logoSmall.default,
      }
  }
}

export function useStaticHeader(brandName = getBrandName()) {
  return ['vwfs'].includes(brandName)
}
