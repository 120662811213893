import 'react-app-polyfill/ie9'
import 'react-app-polyfill/stable'

import React from 'react'
import ReactDOM from 'react-dom'

import { Router, withRouter } from 'react-router-dom'
import { Provider } from 'react-redux'

import initI18n from './services/i18n'
import { history } from './services/routing'
import { store } from './services/redux'

import { BRAND_NAME, PRODUCT } from './config'

const ConnectedApp = React.lazy(() => {
  return import('./App')
})

const getStyles = () => {
  return import('./styles.scss')
}

const PositionRestore = withRouter(props => {
  const [location, setLocation] = React.useState(props.location.pathname)
  if (location !== props.location.pathname) {
    setLocation(props.location.pathname)
    if (props.location.hash && props.location.hash !== '') {
      setTimeout(() => {
        const id = props.location.hash.replace('#', '')
        const element = document.getElementById(id)
        if (element) element.scrollIntoView({ behavior: 'smooth' })
      }, 0)
    } else {
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth',
      })
    }
  }

  return null
})

function Init() {
  const [loaded, setLoaded] = React.useState(false)
  React.useEffect(() => {
    Promise.all([getStyles(BRAND_NAME), initI18n(BRAND_NAME, PRODUCT)]).then(
      () => {
        setLoaded(true)
      }
    )
  }, [])
  return loaded ? (
    <React.Suspense fallback={null}>
      <Provider store={store}>
        <Router history={history}>
          <PositionRestore />
          <ConnectedApp />
        </Router>
      </Provider>
    </React.Suspense>
  ) : null
}

if (module.hot) {
  module.hot.accept(render)
}

function render() {
  const rootElement = document.getElementById('root')
  // eslint-disable-next-line react/no-deprecated
  ReactDOM.render(<Init />, rootElement)
}

try {
  window.jQuery.fn.jquery = ''
} catch (error) {
  console.warn('error hiding verions')
}

try {
  window._.VERSION = ''
} catch (error) {
  console.warn('error hiding verions')
}

render()
