import { pushStorefrontDataToDatalayer } from '../../analytics/storefront'

export const FETCH_STOREFRONT_PENDING = 'storefront//FETCH_STOREFRONT_PENDING'

export const FETCH_STOREFRONT_DONE = 'storefront//FETCH_STOREFRONT_DONE'

export const FETCH_STOREFRONT_ERROR = 'storefront//FETCH_STOREFRONT_ERROR'

export const SET_STATUS = 'storefront//SET_STATUS'

export const SET_PAYMENT_DATA = 'storefront//SET_PAYMENT_DATA'

export function pending() {
  return {
    type: FETCH_STOREFRONT_PENDING,
    payload: {},
  }
}

export function done(data) {
  pushStorefrontDataToDatalayer(data)
  return {
    type: FETCH_STOREFRONT_DONE,
    payload: {
      data,
    },
  }
}

export function error(err) {
  return {
    type: FETCH_STOREFRONT_ERROR,
    payload: {
      err,
    },
  }
}

export function setStatus(data) {
  return {
    type: SET_STATUS,
    payload: data,
  }
}

export function setPaymentData(data) {
  return {
    type: SET_PAYMENT_DATA,
    payload: data,
  }
}

export function getStatus(store) {
  return store.storefront.data?.transaction.status
}

export function getStorefrontData(store) {
  return store.storefront.data
}

export function getStep(store) {
  if (!store?.storefront?.data?.custom) return undefined
  return store?.storefront?.data?.custom.formSection
}

const initialState = {
  pending: false,
  data: {},
  error: null,
}

export default function(state = initialState, action) {
  const { type, payload } = action

  switch (type) {
    case FETCH_STOREFRONT_PENDING:
      return {
        ...state,
        data: {},
        pending: true,
        error: null,
      }

    case FETCH_STOREFRONT_DONE:
      return {
        ...state,
        data: payload.data,
        pending: false,
        error: null,
      }
    case FETCH_STOREFRONT_ERROR:
      return {
        ...state,
        data: {},
        pending: false,
        error: true,
      }
    case SET_STATUS:
      return {
        ...state,
        data: {
          ...state.data,
          transaction: {
            ...state.data.transaction,
            status: payload.data,
          },
        },
        pending: false,
        error: null,
      }

    case SET_PAYMENT_DATA:
      return {
        ...state,
        data: {
          ...state.data,
          customerData: {
            ...state.data.customerData,
            paymentData: {
              iban: payload,
            },
          },
        },
        pending: false,
        error: null,
      }
    default:
      return state
  }
}
