import { get } from 'lodash'
import MOCK_DATA from './data.mock'
import { getLabelForValue } from '../common/form'
import { TransactionStatus } from '../api/journey/update-status.service'

/* tries to get data in order: src1, src2, default */
export const getData = (key, src1 = {}, src2 = {}, defaultValue = '') => {
  /* dev environment: prefill if url-param is set */
  if (process.env.NODE_ENV === 'development') {
    const url = window.location.href
    if (url.substring(url.lastIndexOf('/') + 1).startsWith('prefill?cid=')) {
      return get(src1, key, get(src2, key, get(MOCK_DATA, key, defaultValue)))
    }
  }
  return get(src1, key, get(src2, key, defaultValue)) ?? defaultValue
}

const signatures = {
  JVBERi0: 'application/pdf',
  R0lGODdh: 'image/gif',
  R0lGODlh: 'image/gif',
  iVBORw0KGgo: 'image/png',
  '/9j/': 'image/jpeg',
  Qk0: 'image/bmp',
  Qk1: 'image/bmp',
  Qk2: 'image/bmp',
  Qk3: 'image/bmp',
}

export function detectMimeType(b64) {
  let result = ''
  Object.entries(signatures).forEach(e => {
    if (b64.indexOf(e[0]) === 0) {
      // eslint-disable-next-line prefer-destructuring
      result = e[1]
    }
  })

  return result
}

// export const checkMimetype = value => {
//   let type = ''
//   const res = typeof value === 'string' ? value.split('') : value
//   const arr = new Uint8Array(res).subarray(0, 4)
//   let header = ''
//   for (let i = 0; i < arr.length; i++) {
//     header += arr[i].toString(16)
//   }
//   console.log(header)
//   // Check the file signature against known types
//   switch (header) {
//     // PDF
//     case '25504446':
//       type = 'application/pdf'
//     case '89504e47':
//       type = 'image/png'
//       break
//     case '47494638':
//       type = 'image/gif'
//       break
//     case 'ffd8ffe0':
//     case 'ffd8ffe1':
//     case 'ffd8ffe2':
//     case 'ffd8ffe3':
//     case 'ffd8ffe8':
//       type = 'image/jpeg'
//       break
//     default:
//       type = 'unknown' // Or you can use the blob.type as fallback
//     // throw new Error('unknown type')
//   }
//   return type
// }

// export const convertToBlob = (base64Data, contentType) => {
//   const bytes = new Uint8Array(base64Data.length)

//   for (let i = 0; i < bytes.length; i++) {
//     bytes[i] = base64Data.charCodeAt(i)
//   }
//   return new Blob([bytes], { type: contentType })
// }

// export const base64toBlob = (base64Data, contentType) => {
//   contentType = contentType || ''
//   const sliceSize = 1024
//   const value =
//     base64Data.indexOf && base64Data.indexOf(',') !== -1
//       ? base64Data.split(',')[1]
//       : base64Data

//   const byteCharacters = atob(value)
//   const bytesLength = byteCharacters.length
//   const slicesCount = Math.ceil(bytesLength / sliceSize)
//   const byteArrays = new Array(slicesCount)

//   for (let sliceIndex = 0; sliceIndex < slicesCount; ++sliceIndex) {
//     const begin = sliceIndex * sliceSize
//     const end = Math.min(begin + sliceSize, bytesLength)

//     const bytes = new Array(end - begin)
//     for (let offset = begin, i = 0; offset < end; ++i, ++offset) {
//       bytes[i] = byteCharacters[offset].charCodeAt(0)
//     }
//     byteArrays[sliceIndex] = new Uint8Array(bytes)
//   }
//   return new Blob(byteArrays, { type: contentType })
// }

export const convertFormDataToStorefront = (
  formData,
  dataModels,
  showIncomeAndExpenses
) => {
  const {
    personalDetails,
    contactDetails,
    employmentDetails,
    financialDetails,
    dataProtection,
  } = formData
  const result = {
    customerData: {
      paymentData: {
        iban: financialDetails.iban,
      },
      addresses: {
        street: contactDetails?.street,
        houseNumber: contactDetails?.houseNumber,
        floor: contactDetails?.floor || undefined,
        door: contactDetails?.door || undefined,
        zipCode: contactDetails?.zipCode,
        city: contactDetails?.city,
        countryCode: contactDetails?.countryCode,
        country: getLabelForValue(
          dataModels,
          'countryList',
          contactDetails?.countryCode
        ),
      },
      contactData: {
        email: contactDetails?.email,
        mobilePhoneCountryCode: contactDetails?.mobilePhoneCountryCode,
        mobilePhoneNumber: contactDetails?.mobilePhoneNumber,
      },
      employment: {
        status: employmentDetails?.status,
        occupationGroup: employmentDetails?.occupationGroup || undefined,
        employmentStartDate:
          employmentDetails?.employmentStartDate || undefined,
        employer: employmentDetails?.employmentStartDate
          ? {
              name: employmentDetails?.employerName,
              industry: employmentDetails?.industry,
              street: employmentDetails?.street,
              houseNumber: employmentDetails?.houseNumber,
              zipCode: employmentDetails?.zipCode,
              city: employmentDetails?.city,
              countryCode: employmentDetails?.countryCode,
              country: getLabelForValue(
                dataModels,
                'countryList',
                employmentDetails?.countryCode
              ),
            }
          : undefined,
      },
      financialDisclosure: {
        housingSituation: contactDetails?.housingSituation,
        monthlyIncome: showIncomeAndExpenses
          ? {
              netIncomeAmount: financialDetails?.monthlyIncome,
            }
          : undefined,
        monthlyExpenses: showIncomeAndExpenses
          ? {
              totalExpenses: financialDetails?.monthlyExpenses,
            }
          : undefined,
        invoiceVAT: financialDetails?.invoiceVAT || undefined,
      },
    },
    dataProtection: {
      promotion: {
        transferFinancialServicesSpain:
          dataProtection?.transferFinancialServicesSpain,
        transferVWServicesGroup:
          dataProtection?.transferVWServicesGroup,
        transferVWServicesDealer:
          dataProtection?.transferVWServicesDealer,
      },
      acceptPrivacyPolicy: dataProtection?.acceptPrivacyPolicy,
    },
    transaction: {
      status: TransactionStatus.APPLICATIONFORM_STARTED,
    },
  }
  result.customerData = {
    ...retrievePersonalDetails(personalDetails),
    ...result.customerData,
  }
  return result
}

export const convertDACFormDataToFESLayer = formData => {
  const { personalDetails, dataProtection } = formData
  const result = {
    DACcustomerData: {
      ...retrievePersonalDetails(personalDetails),
      addresses: { zipCode: personalDetails.zipCode },
    },
    DACdataProtection: {
      acceptPrivacyPolicy:
        dataProtection.acceptPrivacyPolicy || dataProtection.acceptAllCheckbox,
      usePersonalData:
        dataProtection.usePersonalData || dataProtection.acceptAllCheckbox,
    },
  }
  return result
}

const retrievePersonalDetails = personalDetails => {
  return {
    gender: personalDetails?.gender,
    firstName: personalDetails?.firstName,
    lastName: personalDetails?.lastName,
    secondaryLastName: personalDetails?.secondaryLastName || undefined,
    dateOfBirth: personalDetails?.dateOfBirth,
    nationality: personalDetails?.nationality,
    maritalStatus: personalDetails?.maritalStatus,
    personalId: {
      id: personalDetails?.personalId,
    },
  }
}

export const addLeadingZero = number => (number <= 9 ? `0${number}` : number)

export const transformDate = date => {
  if (!date) return undefined
  const day = new Date(date).getDate()
  const month = new Date(date).getMonth() + 1
  const year = new Date(date).getFullYear()
  const hour = new Date(date).getHours()
  const minutes = new Date(date).getMinutes()

  const correctDay = addLeadingZero(day)
  const correctMonth = addLeadingZero(month)

  return `${correctDay}.${correctMonth}.${year} ${hour}:${minutes}`
}

export const getValidityDate = () => {
  const date = new Date()
  date.setDate(date.getDate() + 30)
  return transformDate(date)
}
