export const AppRoutes = {
  root: '/',
  leadGeneration: '/lead-generation',
  appForm: '/appForm',
  completion: '/completion',
  upload: '/upload',
  uploadFiles: '/upload-files',
  uploadAdditional: '/upload-additional',
  authenticate: '/authenticate',
  linkExpired: '/link-expired',
  error: '/error',
  page: '/page/:pageName',
  digitalAccountCheck: '/digital-account-check'
}
