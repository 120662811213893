/* eslint-disable global-require */
import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import { isSubscriptionJourney, isOneShop } from '../../helpers'
import { getBrandName } from '../brand'
import { getPrivacyPolicy } from '../../screens/AppForm/DataProtection/initial-values'

import {
  continueLaterLocalization,
} from '@vwfs-its/sf-sac-frontend'
i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources: {},
    lng: process.env.REACT_APP_COUNTRYCODE,
    keySeparator: '.', // we do not use keys in form messages.welcome
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  })

export default brandName => {
  return new Promise(res => {
    const namespaces = [
      'lead-generation',
      'form',
      'data',
      'contact',
      'precompletion',
      'completion',
      'validation',
      'footer',
      'header',
      'error',
      'cookie',
      'offer',
      'upload',
      'upload-additional',
      'link-expired',
      'dac-selection',
      'dac',
      'dacInstantor',
      'S&C',
    ]
    Promise.all(
      namespaces.map(item => {
        return import(`../../resources/base/content/${item}.json`)
          .then(module => {
            i18n.addResourceBundle(
              process.env.REACT_APP_COUNTRYCODE,
              item,
              module.default,
              true,
              true
            )
          })
          .then(() => {
            /**
             * Request brand-specific resources and override (using deep) the
             * resources for specific brand. Make sure to chain those to avoid
             * race conditions.
             */
            let brandWithProduct = brandName
            if (isSubscriptionJourney()) {
              brandWithProduct = `${brandName}-subscription`
            } else if (isOneShop()) {
              brandWithProduct = `${brandName}-oneshop`
            }

            import(`../../resources/${brandWithProduct}/content/${item}.json`)
              .then(module => {
                i18n.addResourceBundle(
                  process.env.REACT_APP_COUNTRYCODE,
                  item,
                  module.default,
                  true,
                  true
                )
              })
              .catch(() => {})
          })
      })
    ).then(() => {
        const localization = continueLaterLocalization({
          locale: 'es',
          brandName: getBrandName(),
          dataProtectionLink: getPrivacyPolicy(),
        })
        i18n.addResourceBundle('es', 'sf-sac', localization, true, true)
      
    }).then(res)
  })
}
