/* eslint-disable no-nested-ternary */
/* eslint-disable no-underscore-dangle */
import { createStore, combineReducers, applyMiddleware, compose } from 'redux'
import logger from 'redux-logger'

import formReducer from './features/form.redux'
import dataModelsReducer from './features/data-models.redux'
import storefrontReducer from './features/storefront.redux'
import dataStorageReducer from './features/data-storage.redux'
import tokenReducer from './features/token.redux'

const reducer = combineReducers({
  form: formReducer,
  dataModel: dataModelsReducer,
  storefront: storefrontReducer,
  dataStorage: dataStorageReducer,
  token: tokenReducer,
})

const store = createStore(
  reducer,
  {},
  process.env.REACT_APP_WEBSITE_STAGE.toLowerCase() === 'dev'
    ? compose(
        applyMiddleware(logger),
        window.__REDUX_DEVTOOLS_EXTENSION__
          ? window.__REDUX_DEVTOOLS_EXTENSION__()
          : f => f
      )
    : window.__REDUX_DEVTOOLS_EXTENSION__
    ? window.__REDUX_DEVTOOLS_EXTENSION__()
    : f => f
)

export { store }
